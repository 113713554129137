import timg1 from "../images/team/team_member_image_1.webp";
import timg2 from "../images/team/team_member_image_2.webp";

const Teams = [
  {
    Id: 1,
    tImg: timg1,
    name: "Humbulani Mulaudzi",
    slug: "Thee_Speaker",
    title: "Chief Marketing Officer",
    qualifications: ["Bsc CS", "Business Mngt", "Software Development"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [44, 87, 23, 92],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
  {
    Id: 2,
    tImg: timg2,
    name: "Peace Munyayi",
    slug: "Peace_Thee_Coder",
    title: "Founder",
    qualifications: ["Bsc CS", "Statistics", "Web Development"],
    profSkills: [
      "Product Management",
      "Product Development",
      "Marketing & Sales",
      "Customer Relations",
    ],
    countUps: [65, 92, 83, 33],
    socialLinks: {
      facebook: "https://facebook.com",
      twitter: "https://x.com",
      linkedIn: "https://linkedin.com",
      instagram: "https://instagram.com",
    },
  },
];

export default Teams;
