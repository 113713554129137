import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import aImg from "../../images/team/team_poster.png";
import aImg2 from "../../images/about/about_image_4.webp";
import ModalVideo from "react-modal-video";
import PolicySection from "./Policy";
import WhyUs from "./WhyUs";
import TeamSection from "../../components/TeamSection/TeamSection";
import CtaSection from "../../components/CtaSection/CtaSection";

const AboutUsPage = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <PageTitle
          pageTop={"About"}
          pagesub={"Eragateway 😃"}
          pageTitle={"Who We Are"}
        />
        <section className="intro_about_section section_space bg-light">
          <div className="intro_about_content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="image_wrap">
                    <img src={aImg} alt="Eragateway - About " />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="image_wrap position-relative">
                    <img src={aImg2} alt="Eragateway - About" />
                    <button
                      className="video_btn ripple_effect"
                      onClick={() => setOpen(true)}
                    >
                      <span className="btn_icon">
                        <i className="fa-solid fa-play"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="heading_block mb-0">
              <div className="row justify-content-lg-between">
                <div className="col-lg-4">
                  <div className="heading_focus_text">
                    Here at
                    <span className="badge bg-secondary text-white">
                      Eragateway 🙂
                    </span>
                  </div>
                  <h2 className="heading_text mb-0">
                    Provide You With Flexible Services
                  </h2>
                </div>
                <div className="col-lg-6">
                  <p className="heading_description mb-0">
                    Welcome to ERAGATEWAY(PTY) LTD We are a progressive system
                    development, software designs and maintenance company, web
                    application focused on delivering advanced technology
                    solutions that enables our client's success in fast changing
                    digital environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PolicySection />
        <TeamSection />
        <WhyUs />
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
export default AboutUsPage;
