import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import Project from "../../api/project";
// import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";

const PortfolioSinglePage = (props) => {
  const { slug } = useParams();
  const PortfolioDetails = Project.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Header />
      <PageTitle
        pageTitle={PortfolioDetails.title}
        pagesub={"Details 😃"}
        pageTop={"Portfolio"}
      />
      <section
        className="portfolio_details_section section_space bg-light"
        style={{ paddingBottom: "50px" }}
      >
        <div className="container">
          <div className="details_item_image">
            <img src={PortfolioDetails.pImg} alt="Portfolio Item" />
          </div>
          <h2 className="details_item_title">{PortfolioDetails.title}</h2>
          <p>{PortfolioDetails.description}</p>

          <hr />

          <h3 className="details_item_info_title">Key Features:</h3>
          <ol style={{ margin: 0 }}>
            {PortfolioDetails.keyFeatures.features.map((feature) => (
              <div>
                <li key={feature.title} style={{ fontWeight: "600" }}>
                  {feature.title}:
                </li>
                <p style={{ margin: 0 }} className="portfolio_body">
                  {feature.body}
                </p>
              </div>
            ))}
          </ol>
        </div>
      </section>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PortfolioSinglePage;
