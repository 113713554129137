import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom"; // Import NavLink
import logo from "../../images/site_logo/site-logo1.png";
import logo2 from "../../images/site_logo/2.png";
import MobileMenu from "../MobileMenu/MobileMenu";
import "../../sass/active.scss";

const Header2 = (props) => {
  const [mobailActive, setMobailState] = useState(false);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="site_header site_header_2">
      <div
        className={`header_bottom stricky ${
          isSticky ? "stricked-menu stricky-fixed" : ""
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2 col-5">
              <div className="site_logo">
                <Link onClick={ClickHandler} className="site_link" to="/">
                  <img src={logo} alt="Site Logo" />
                  <img src={logo2} alt="Site Logo" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-2">
              <nav
                className="main_menu navbar navbar-expand-lg"
                style={{ marginLeft: "39px" }}
              >
                <div
                  className="main_menu_inner collapse navbar-collapse justify-content-lg-center"
                  id="main_menu_dropdown"
                >
                  <ul
                    className="main_menu_list unordered_list justify-content-center"
                    style={{ marginRight: "70px" }}
                  >
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/"
                        activeClassName="active" // Add active class
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        to="/service"
                        activeClassName="active"
                      >
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        to="/about"
                        activeClassName="active"
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        to="/team"
                        activeClassName="active"
                      >
                        Team
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        to="/portfolio"
                        activeClassName="active"
                      >
                        Portfolio
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={ClickHandler}
                        to="/contact"
                        activeClassName="active"
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-xl-3 col-lg-3 col-5">
              <ul className="header_btns_group unordered_list justify-content-end">
                <li>
                  <button
                    className="mobile_menu_btn"
                    onClick={() => setMobailState(!mobailActive)}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_menu_dropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="far fa-bars"></i>
                  </button>
                </li>
                <li>
                  <NavLink
                    onClick={ClickHandler}
                    className="btn btn-primary"
                    to="/contact"
                    activeClassName="active"
                  >
                    <span className="btn_label" data-text="Get Started">
                      Get Started
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobail-wrap">
          <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
            <div className="xb-header-menu-scroll">
              <div
                className="xb-menu-close xb-hide-xl xb-close"
                onClick={() => setMobailState(!mobailActive)}
              ></div>
              <nav className="xb-header-nav">
                <MobileMenu />
              </nav>
            </div>
          </div>
          <div
            className="xb-header-menu-backdrop"
            onClick={() => setMobailState(false)}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default Header2;
