import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../node_modules/react-modal-video/scss/modal-video.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/fontawesome.css";
import "./css/themify-icons.css";
import "./css/animate.css";
import "./sass/style.scss";
import "aos/dist/aos.css";
import AOS from "aos";

AOS.init({
  duration: 1000, // Duration of the animation in milliseconds
  easing: "ease-in-out", // Easing function
  once: true, // Whether animation should happen only once
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
