import React, { Fragment, useState } from "react";
import Services from "../../api/service";
import { useParams } from "react-router-dom";
import ModalVideo from "react-modal-video";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import ProcessTechnology from "../../components/software-company-components/ProcessTechnology/ProcessTechnology";

const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const ServiceDetails = Services.find((item) => item.slug === slug);

  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Header />
      <main className="page_content service-single-page">
        <PageTitle
          pageTitle={ServiceDetails.title}
          pagesub={"Details 😍"}
          pageTop={"Services"}
        />
        <section className="service_details_section section_space bg-light">
          <div className="container">
            <h2 className="details_item_title">{ServiceDetails.title}</h2>
            <p>{ServiceDetails.description}</p>

            <ProcessTechnology />
          </div>
        </section>
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
export default ServiceSinglePage;
