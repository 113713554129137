import portfolio_1 from "../images/portfolio/port1.png";
import portfolio_2 from "../images/portfolio/port2.png";
import portfolio_3 from "../images/portfolio/port3.png";
import portfolio_4 from "../images/portfolio/port4.png";
import portfolio_5 from "../images/portfolio/port5.png";

import hosthek_img from "../images/portfolio/hosthek.png";

const Project = [
  {
    Id: "1",
    pImg: portfolio_1,
    title: "EA Converter: Mobile Trading Revolution",
    slug: "EA_Converter",
    sub: "web_app_dev",
    keyFeatures: {
      features: [
        {
          title: "EA Conversion",
          body: "Easily transform your desktop EAs into mobile-compatible trading robots, enabling you to trade on your mobile device with your existing strategies and algorithms.",
        },
        {
          title: "Seamless Integration",
          body: "The converted EAs seamlessly integrate with our dedicated mobile app, offering a user-friendly and intuitive interface. This allows you to monitor your trades, access real-time market data, and make informed decisions right from your mobile device.",
        },
        {
          title: "Monthly Subscription",
          body: "Gain access to EA Converter by subscribing to our monthly plan. Our subscription model ensures that you have continuous access to the app, as well as updates and improvements, to enhance your trading experience.",
        },
      ],
    },
    description:
      "EA Converter is a cutting-edge platform designed to empower traders by converting their Expert Advisors (EAs) into mobile trading robots. With EA Converter, you can take your trading on the go, eliminating the need for a desktop platform. Simply convert your EAs, and access them through our intuitive mobile app, allowing you to trade from anywhere, at any time.",
  },
  {
    Id: "2",
    pImg: portfolio_2,
    title: "Matric Exam Papers",
    slug: "Matric_Exam_Papers",
    sub: "education",

    keyFeatures: {
      features: [
        {
          title: "Extensive Exam Paper Library",
          body: "Gain access to a wide range of matric exam papers from previous years, spanning multiple subjects and examination boards. This comprehensive collection allows you to practice with real exam questions and better understand the exam format.",
        },
        {
          title: "Subject Categorization",
          body: "Easily navigate through different subjects with a user-friendly interface. Organized categorization helps you locate and access the exam papers relevant to your specific subject of interest.",
        },
        {
          title: "Search and Filter Functionality",
          body: "Quickly find specific exam papers by utilizing the search and filter features. Filter papers by subject, year, examination board, or other criteria, enabling you to streamline your study materials based on your preferences.",
        },
      ],
    },
    description:
      "Introducing the Matric Exam Papers App, your go-to study companion for accessing past examination papers effortlessly. Developed with students in mind, this comprehensive mobile application provides a seamless and convenient way to prepare for your matric exams. With the Matric Exam Papers App, you can access a vast collection of past examination papers from various subjects, including Mathematics, English, Science, History, and more. Whether you want to practice solving exam-style questions, familiarize yourself with the exam format, or assess your knowledge, this app has you covered.",
  },

  {
    Id: "3",
    pImg: portfolio_3,
    title: "The Dollar Bot: Mobile Trading Robot",
    slug: "Dollar_Bot",
    sub: "Web_app_dev",
    keyFeatures: {
      features: [
        {
          title: "Empowers Traders",
          body: "Easily transform your desktop EAs into mobile-compatible trading robots, enabling you to trade on your mobile device with your existing strategies and algorithms.",
        },
        {
          title: "Seamless Integration",
          body: "The converted EAs seamlessly integrate with our dedicated mobile app, offering a user-friendly and intuitive interface. This allows you to monitor your trades, access real-time market data, and make informed decisions right from your mobile device.",
        },
        {
          title: "Monthly Subscription",
          body: "Gain access to EA Converter by subscribing to our monthly plan. Our subscription model ensures that you have continuous access to the app, as well as updates and improvements, to enhance your trading experience.",
        },
      ],
    },
    description:
      "The Dollar Bot is a cutting-edge mobile trading robot designed to streamline and enhance your trading experience. With a focus on automation and efficiency, this innovative solution allows you to take advantage of market opportunities and make informed trading decisions with ease. By visiting our website, thedollarbot.com, you can conveniently purchase a license key to unlock the full potential of The Dollar Bot. Our secure and user-friendly platform integrates with PayFast, a trusted payment gateway, ensuring a seamless and secure transaction process.",
  },
  {
    Id: "4",
    pImg: portfolio_4,
    title: "Eskom Loadshedding",
    slug: "Mobile-App-Design",
    sub: "App Design",
    keyFeatures: {
      features: [
        {
          title: "Accurate Loadshedding Schedule",
          body: "Access up-to-date load shedding schedules specific to your location. Our app synchronizes with Eskom's latest data to provide you with accurate timings, ensuring you are always aware of when power will be affected.",
        },
        {
          title: "Customized Notifications",
          body: "Stay informed with personalized notifications based on your selected areas and preferences. Receive timely alerts before load shedding starts, enabling you to plan and adjust your activities accordingly.",
        },
        {
          title: "Power Restoration Updates",
          body: "Get real-time notifications when power is restored in your area. No more guessing or constant checking—our app ensures you are promptly notified when the lights are back on.",
        },
        {
          title: "User-Friendly Interface",
          body: "Enjoy a visually appealing and user-friendly design that enhances your overall experience. Effortlessly navigate through various features and sections, making it simple to access the information you need.",
        },
      ],
    },
    description:
      "Are you tired of being caught off guard by load shedding? Look no further! The Eskom Loadshedding App is here to empower you with real-time information and effective management of power disruptions. As a software developer, I've crafted this comprehensive mobile application to provide a seamless experience for Android and iOS users. With its intuitive interface and robust functionality, the Eskom Loadshedding App keeps you informed and prepared for any upcoming load shedding events.",
  },
  {
    Id: "5",
    pImg: portfolio_5,
    title: "Learners Bursary: Student Opportunities website",
    slug: "Learners_Bursary",
    sub: "education",
    keyFeatures: {
      features: [
        {
          title: "User-freindly Platform",
          body: "Learners Bursary provides comprehensive information about various bursaries and scholarships. These financial awards can help alleviate the burden of educational expenses and empower students to focus on their studies.",
        },
        {
          title: "Easy Navigation",
          body: "Learners Bursary recognizes the significance of these opportunities in shaping students' academic and professional journeys. By providing a centralized platform for students to explore and access internships, learnerships, and bursaries, the website aims to support students in their pursuit of educational and career success.",
        },
        {
          title: "SMS Updates",
          body: " Through its listings of internships and learnerships, the website connects students with organizations that offer valuable hands-on training and mentorship. This exposure enables students to apply their academic knowledge in real-world settings, develop crucial skills, and build a strong foundation for their future careers.",
        },
      ],
    },
    description:
      "Learners Bursary is a dedicated website that focuses on providing students with comprehensive information about various opportunities available to them, including internships, learnerships, and bursaries. It serves as a valuable resource for students who are seeking financial support, practical work experience, or specialized training to enhance their educational journey and career prospects. The website offers a user-friendly platform where students can explore a wide range of opportunities across different industries and fields of study. Learners Bursary strives to compile an extensive database of internships, learnerships, and bursaries, making it easier for students to find relevant opportunities that align with their interests and aspirations.",
  },
  {
    Id: "6",
    pImg: hosthek_img,
    title: "Hosthek: Reliable Web Hosting Solutions for Your Online Presence",
    slug: "Hosthek",
    sub: "Web_app_dev",
    keyFeatures: {
      features: [
        {
          title: "Scalability",
          body: "Our services include scalable hosting plans, domain registration, SSL certificates, and advanced security features to safeguard your valuable online assets. Whether you're launching a small business website or managing a high-traffic e-commerce platform, Hosthek offers the flexibility and support you need to thrive in the digital landscape.",
        },
        {
          title: "Seamless Control Panel",
          body: "Experience seamless website management with our user-friendly control panel, allowing you to effortlessly manage domains, databases, emails, and more. Our knowledgeable support team is available around the clock to assist you with any technical queries or concerns, ensuring a smooth hosting experience.",
        },
        {
          title: "Maintained Online Presents",
          body: "Trust Hosthek to be your reliable partner in establishing and maintaining your online presence. Focus on your core business while we handle the technical aspects of web hosting, providing you with the peace of mind to grow and succeed in the digital world.",
        },
      ],
    },
    description:
      "Hosthek is a trusted web hosting platform dedicated to providing reliable and secure hosting solutions for businesses of all sizes. With our cutting-edge infrastructure and robust technologies, we ensure that your website remains accessible and optimized for superior performance. Our services include scalable hosting plans, domain registration, SSL certificates, and advanced security features to safeguard your valuable online assets. Whether you're launching a small business website or managing a high-traffic e-commerce platform, Hosthek offers the flexibility and support you need to thrive in the digital landscape.",
  },
];

export default Project;
