import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import cnIcon1 from "../../../images/icons/icon_php.svg";
import cnIcon2 from "../../../images/icons/icon_javascript.svg";
import cnIcon3 from "../../../images/icons/css.png";
import cnIcon4 from "../../../images/icons/icons8-tailwind-css.svg";
import cnIcon5 from "../../../images/icons/sass.png";
import cnIcon7 from "../../../images/icons/icons8-node-js.svg";
import cnIcon8 from "../../../images/icons/PhpMyAdmin_logo.png";
import cnIcon9 from "../../../images/icons/icons8-mysql-240.png";
import cnIcon11 from "../../../images/icons/icon_react_js.svg";
import cnIcon12 from "../../../images/icons/icon_laravel.svg";
import cnIcon13 from "../../../images/icons/bootstrap.png";
import cnIcon14 from "../../../images/icons/wordpress.png";
import cnIcon15 from "../../../images/icons/icons8-django.svg";
import cnIcon16 from "../../../images/icons/postgresql.svg";
import cnIcon17 from "../../../images/icons/mongodb.svg";
import cnIcon18 from "../../../images/icons/flutter.svg";
import cnIcon19 from "../../../images/icons/swift.svg";
import cnIcon20 from "../../../images/icons/java.svg";
import cnIcon21 from "../../../images/icons/kotlin.svg";
import Bg from "../../../images/shapes/shape_title_under_line.svg";

const WebPlatform = [
  {
    Id: 1,

    sIcon: cnIcon2,
    title: "JavaScript",
  },
  {
    Id: 2,
    sIcon: cnIcon11,
    title: "React Js",
  },
  {
    Id: 3,
    sIcon: cnIcon3,
    title: "CSS",
  },
  {
    Id: 4,
    sIcon: cnIcon4,
    title: "Tailwind CSS",
  },
  {
    Id: 5,
    sIcon: cnIcon5,
    title: "SCSS",
  },

  {
    Id: 7,
    sIcon: cnIcon7,
    title: "Node JS",
  },
  {
    Id: 8,
    sIcon: cnIcon1,
    title: "PHP",
  },
  {
    Id: 9,
    sIcon: cnIcon13,
    title: "Bootstrap",
  },
  {
    Id: 10,
    sIcon: cnIcon14,
    title: "WordPress",
  },
];

const Database = [
  {
    Id: 1,
    sIcon: cnIcon17,
    title: "Mongo Db",
  },
  {
    Id: 2,
    sIcon: cnIcon16,
    title: "PostgreSQL",
  },
  {
    Id: 3,
    sIcon: cnIcon8,
    title: "PHPMyAdmin",
  },
  {
    Id: 4,
    sIcon: cnIcon9,
    title: "MySQL",
  },
];

const MobileApps = [
  {
    Id: 4,
    sIcon: cnIcon20,
    title: "Java",
  },
  {
    Id: 5,
    sIcon: cnIcon21,
    title: "Kotlin",
  },
  {
    Id: 6,
    sIcon: cnIcon19,
    title: "Swift",
  },
  {
    Id: 7,
    sIcon: cnIcon18,
    title: "Flutter",
  },
  
];

const frameworks = [
  {
    Id: 1,
    sIcon: cnIcon11,
    title: "React Js",
  },
  {
    Id: 2,
    sIcon: cnIcon4,
    title: "Tailwind CSS",
  },
  {
    Id: 3,
    sIcon: cnIcon12,
    title: "Laraval",
  },
  {
    Id: 4,
    sIcon: cnIcon13,
    title: "Bootstrap",
  },
  {
    Id: 5,
    sIcon: cnIcon14,
    title: "WordPress",
  },
  {
    Id: 6,
    sIcon: cnIcon15,
    title: "Django Rest Framework",
  },
];

const TechnologySection = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="section_space">
      <div className="heading_block text-center">
        <div
          className="heading_focus_text has_underline d-inline-flex"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          Our Technologies
        </div>
        <h2 className="heading_text mb-0">
          <mark>Technologies</mark> We Use
        </h2>
      </div>

      <div className="tab_block_wrapper">
        <Nav tabs className="nav justify-content-center">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Web Platform
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Databases
            </NavLink>
          </NavItem>
          <NavItem></NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              Mobile Apps
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              Frameworks
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="web_development_technologies row justify-content-center">
              {WebPlatform.map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="Tech Stack" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="web_development_technologies row justify-content-center">
              {Database.map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="Icon" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>

          <TabPane tabId="4">
            <div className="web_development_technologies row justify-content-center">
              {MobileApps.map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="Icon" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="5">
            <div className="web_development_technologies row justify-content-center">
              {frameworks.map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="Icon" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TechnologySection;
