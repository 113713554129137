import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import api_img from "../../images/services/application-programming-interface-hologram.jpg";
import it_img from "../../images/services/data-center-technician-runs-code.jpg";
import system_img from "../../images/services/person-working-html-computer.jpg";
import software_img from "../../images/services/software.jpg";
import mob_app_img from "../../images/services/high-angle-designer-holding-smartphone.jpg";
import WhyUs from "../AboutUsPage/WhyUs";

const ServicePage = (props) => {
  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <PageTitle
          pageTitle={"Our Services"}
          pagesub={"Services 😍"}
          pageTop={"Our Main"}
        />
        <section className="about_section section_space bg-light">
          <div className="container">
            <div className="row align-items-center justify-content-lg-evenly">
              <div className="col-lg-5 order-lg-last" data-aos="fade-right">
                <div className="team_cartoon_image">
                  <img src={it_img} alt="Service Cartoon" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about_content">
                  <div className="heading_block">
                    <div className="heading_focus_text">
                      Eragateway
                      <span className="badge bg-secondary text-white">
                        IT Consultation 🙂
                      </span>
                    </div>
                    <h2 className="heading_text" style={{ fontWeight: "400" }}>
                      Tailored IT Solutions
                    </h2>
                    <p className="heading_description">
                      Our IT consulting services are designed to empower
                      businesses with strategic insights and technical
                      solutions. From system integration to digital
                      transformation, our experts work closely with you to
                      address unique challenges, optimize processes, and enhance
                      your technological capabilities.
                    </p>
                  </div>
                  <Link
                    to={"/contact"}
                    className="btn service_action_button"
                    data-aos="fade-right"
                  >
                    <span
                      className="btn_label"
                      data-text="Talk to an Expert"
                      data-aos="fade-right"
                    >
                      Talk to an Expert
                    </span>
                    <span className="btn_icon" data-aos="fade-right">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_section section_space bg-light">
          <div className="container">
            <div
              className="row align-items-center justify-content-lg-evenly"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div className="col-lg-5 order-lg-last" data-aos="fade-right">
                <div className="team_cartoon_image">
                  <img src={system_img} alt="Service Cartoon" />
                </div>
              </div>
              <div className="col-lg-5" data-aos="fade-up">
                <div className="about_content">
                  <div className="heading_block">
                    <div className="heading_focus_text">
                      Eragateway
                      <span className="badge bg-secondary text-white">
                        System Design 🙂
                      </span>
                    </div>
                    <h2 className="heading_text">Tailored System Designs</h2>
                    <p className="heading_description mb-0">
                      We create robust, user-friendly web systems tailored to
                      meet your business goals. Whether you need a secure
                      e-commerce platform or a comprehensive enterprise
                      solution, we ensure an optimal user experience and
                      seamless functionality.
                    </p>
                  </div>
                  <Link to={"/contact"} className="btn" data-aos="zoom-in">
                    <span className="btn_label" data-text="Talk to an Expert">
                      Talk to an Expert
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_section section_space bg-light">
          <div className="container">
            <div className="row align-items-center justify-content-lg-evenly">
              <div className="col-lg-5 order-lg-last" data-aos="fade-left">
                <div className="team_cartoon_image">
                  <img src={software_img} alt="Service Cartoon" />
                </div>
              </div>
              <div className="col-lg-5" data-aos="fade-up">
                <div className="about_content">
                  <div className="heading_block">
                    <div className="heading_focus_text">
                      Eragateway
                      <span className="badge bg-secondary text-white">
                        Software Development 🙂
                      </span>
                    </div>
                    <h2 className="heading_text">Tailored Software</h2>
                    <p className="heading_description mb-0">
                      Our team specializes in developing customized software
                      solutions that drive efficiency and scalability. We
                      provide continuous support and maintenance, ensuring your
                      software remains up-to-date and fully operational as your
                      business grows.
                    </p>
                  </div>
                  <Link to={"/contact"} className="btn" data-aos="zoom-in">
                    <span className="btn_label" data-text="Talk to an Expert">
                      Talk to an Expert
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_section section_space bg-light">
          <div className="container">
            <div
              className="row align-items-center justify-content-lg-evenly"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div className="col-lg-5 order-lg-last" data-aos="zoom-in">
                <div className="team_cartoon_image">
                  <img src={mob_app_img} alt="Service Cartoon" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about_content">
                  <div className="heading_block">
                    <div className="heading_focus_text">
                      Eragateway
                      <span className="badge bg-secondary text-white">
                        Mobile Apps 🙂
                      </span>
                    </div>
                    <h2 className="heading_text" data-aos="fade-left">
                      Tailored Mobile Apps
                    </h2>
                    <p className="heading_description mb-0">
                      In today’s mobile-first world, we design intuitive,
                      high-performance mobile applications that engage users and
                      drive business growth. From concept to deployment, our
                      mobile solutions are crafted to provide a seamless
                      experience on any device.
                    </p>
                  </div>
                  <Link to={"/contact"} className="btn" data-aos="zoom-in">
                    <span className="btn_label" data-text="Talk to an Expert">
                      Talk to an Expert
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_section section_space bg-light">
          <div className="container">
            <div className="row align-items-center justify-content-lg-evenly">
              <div className="col-lg-5 order-lg-last" data-aos="fade-right">
                <div className="team_cartoon_image">
                  <img src={api_img} alt="Service Cartoon" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about_content">
                  <div className="heading_block" data-aos="fade-left">
                    <div className="heading_focus_text">
                      Eragateway
                      <span className="badge bg-secondary text-white">
                        API Development 🙂
                      </span>
                    </div>
                    <h2 className="heading_text">Tailored API Solutions</h2>
                    <p className="heading_description mb-0">
                      Our API development services enable seamless data
                      connectivity and integration across your business
                      applications. We build APIs that facilitate smooth
                      communication between systems, enhancing functionality and
                      boosting operational efficiency.
                    </p>
                  </div>
                  <Link to={"/contact"} className="btn" data-aos="zoom-out">
                    <span className="btn_label" data-text="Talk to an Expert">
                      Talk to an Expert
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WhyUs />
      </main>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
