import React from "react";
import Teams from "../../api/team";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import sImg1 from "../../images/icons/icon_facebook.svg";
import sImg2 from "../../images/icons/icon_twitter_x.svg";
import sImg3 from "../../images/icons/icon_linkedin.svg";
import sImg4 from "../../images/icons/icon_instagram.svg";

const TeamSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <>
      <section className="team_section xb-hidden section_space">
        <div className="container">
          <div className="heading_block text-center">
            <div className="heading_focus_text">
              <span className="badge bg-secondary text-white">Our Expert</span>
              Team Members 😍
            </div>
            <h2 className="heading_text mb-0">Our Skilled Experts</h2>
          </div>

          <div className="team_carousel">
            <Swiper
              loop={true}
              spaceBetween={30}
              allowTouchMove={true}
              centeredSlides={true}
              pagination={{ clickable: true }}
              speed={800}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                },
                1025: {
                  slidesPerView: 3,
                },
              }}
            >
              {Teams.map((team) => (
                <SwiperSlide key={team.id}>
                  {" "}
                  {/* Use team.id for a unique key */}
                  <div className="team_block">
                    <div className="team_member_image">
                      <Link
                        onClick={ClickHandler}
                        className="image_wrap"
                        aria-label="Team Details Button"
                        to={`/team-single/${team.slug}`}
                      >
                        <img src={team.tImg} alt="" />
                        <i className="fa-solid fa-arrow-up-right"></i>
                      </Link>
                    </div>
                    <div className="team_member_info">
                      <h3 className="team_member_name">
                        <Link
                          onClick={ClickHandler}
                          to={`/team-single/${team.slug}`}
                        >
                          {team.name}
                        </Link>
                      </h3>
                      <h4 className="team_member_designation">{team.title}</h4>
                      {/* Display social icons only for members with ID 1 and 2 */}
                      {team.Id === 6 && (
                        <h4 className="team_member_designation">.</h4>
                      )}
                      {team.Id === 1 || team.Id === 2 ? (
                        <ul className="social_icons_block unordered_list justify-content-center">
                          <li>
                            <Link
                              onClick={ClickHandler}
                              to={`/team-single/${team.slug}`}
                            >
                              <img src={sImg1} alt="Icon Facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={ClickHandler}
                              to={`/team-single/${team.slug}`}
                            >
                              <img src={sImg2} alt="Icon Twitter X" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={ClickHandler}
                              to={`/team-single/${team.slug}`}
                            >
                              <img src={sImg3} alt="Icon Linkedin" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={ClickHandler}
                              to={`/team-single/${team.slug}`}
                            >
                              <img src={sImg4} alt="Icon Instagram" />
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      <div className="container" style={{ marginBottom: "50px" }}>
        <div className="btns_group pb-0">
          <Link className="btn btn-outline-light" to="/team">
            <span className="btn_label" data-text="All Our Experts">
              All Our Experts
            </span>
            <span className="btn_icon">
              <i className="fa-solid fa-arrow-up-right"></i>
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TeamSection;
